import { usersConstants } from '@constants'
import api from '@api'
import { appActions } from '@actions'

export const setActiveUser = (user) => {
  return (dispatch) => {
    dispatch({
      type: usersConstants.SET_ACTIVE_USER,
      payload: user,
    })
  }
}

export const checkUsername = ($username) => {
  return async (dispatch) => {
    try {
      // const { data } = await api.checkUsername($username)
    } catch (error) {
      console.log(error)
    }
  }
}

export const getUsers = ($regex, $offset, $limit) => {
  return async (dispatch) => {
    try {
      let $formatedRegex = $regex.replace(`+`, `\\+`)
      const { data } = await api.getUsers($formatedRegex, $offset, $limit)
      dispatch({
        type: usersConstants.SET_USERS,
        payload: data,
      })
    } catch (error) {
      dispatch(appActions.toggleError(error))
      dispatch(appActions.toggleLoading())
    }
  }
}

export const getUsersSearchResult = ($regex, $offset, $limit) => {
  return async (dispatch) => {
    try {
      let $formatedRegex = $regex.replace(`+`, `\\+`)
      const { data } = await api.getActiveUsers($formatedRegex, $offset, $limit)
      dispatch({
        type: usersConstants.SET_USERS_SEARCH_RESULT,
        payload: data,
      })
    } catch (error) {
      dispatch(appActions.toggleError(error))
      dispatch(appActions.toggleLoading())
    }
  }
}

export const clearUsersSearchResult = () => {
  return (dispatch) => {
    dispatch({
      type: usersConstants.CLEAR_USERS_SEARCH_RESULT,
    })
  }
}

export const editUser = ({
  userId,
  name,
  description,
  username,
  phoneNumber,
  email,
  avatarUrl,
}) => {
  return async (dispatch) => {
    try {
      await api.editUser(
        userId,
        username,
        email,
        phoneNumber,
        name,
        avatarUrl,
        description,
      )
      dispatch(getUsers('', 0, 100))
    } catch (error) {
      console.log(error)
    }
  }
}

export const addUser = ($newUser) => {
  return async (dispatch) => {
    const {
      password,
      username,
      email,
      phoneNumber,
      name,
      avatarUrl,
      description,
    } = $newUser
    try {
      await api.addUser(
        password,
        username,
        email,
        phoneNumber,
        name,
        avatarUrl,
        description,
      )
      dispatch(getUsers('', 0, 100))
    } catch (err) {
      alert(`add user error`)
    }
  }
}

export const blockUsers = ($userIds, $searchValue) => {
  return async (dispatch) => {
    await api.blockUsers($userIds)

    if (!$searchValue) {
      dispatch(getUsers('', 0, 100))
    } else {
      dispatch(getUsersSearchResult($searchValue, 0, 100))
    }
  }
}

export const unblockUsers = ($userIds, $searchValue) => {
  return async (dispatch) => {
    await api.unblockUsers($userIds)

    if (!$searchValue) {
      dispatch(getUsers('', 0, 100))
    } else {
      dispatch(getUsersSearchResult($searchValue, 0, 100))
    }
  }
}

export const deleteUsers = ($userIds, $searchValue) => {
  return async (dispatch) => {
    try {
      await api.deleteUsers($userIds)

      if (!$searchValue) {
        dispatch(getUsers('', 0, 100))
      } else {
        dispatch(getUsersSearchResult($searchValue, 0, 100))
      }
    } catch (error) {
      console.log(error)
    }
  }
}

export const activateUsers = ($userIds, $searchValue) => {
  return async (dispatch) => {
    try {
      await api.activateUsers($userIds)

      if (!$searchValue) {
        dispatch(getUsers('', 0, 100))
      } else {
        dispatch(getUsersSearchResult($searchValue, 0, 100))
      }
    } catch (error) {
      console.log(error)
    }
  }
}

export const eraseUsers = ($userIds) => {
  return async (dispatch) => {
    try {
      await api.eraseUsers($userIds)
      dispatch(getUsers('', 0, 100))
    } catch (error) {
      console.log(error)
    }
  }
}
