import React from 'react'
import ContentBlock from '@sc-reactkit/content-block'
import { Text } from '@sc-reactkit/typography'

const ErrorBlock = ({ text }) => {
    return (
        <ContentBlock>
            <Text color="red" bold>
                { text }
            </Text>
        </ContentBlock>
    )
}

export default ErrorBlock