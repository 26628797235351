import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect, Switch, withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { appActions } from '@actions'
import { appConstants } from '@constants'

const AuthPage = React.lazy(() => import('@pages/AuthPage'))

const Auth = ({ auth, children }) => {
  const authContent = () => (
    <Switch>
      <Route path="/login" component={AuthPage} />
      <Redirect to="/login" />
    </Switch>
  )

  return <>{auth === appConstants.AUTH ? children : authContent()}</>
}

const mapStateToProps = ({ app }) => ({
  ...app,
})

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(appActions, dispatch),
})

const AuthGuard = connect(mapStateToProps, mapDispatchToProps)(Auth)
export default withRouter(AuthGuard)
