import React from 'react'
import styled from 'styled-components'
import i18next from 'i18next';

const UserLabel = ({ name, info, img = null }) => {
  const date = new Date(info)
  return (
    <SUserLabelContainer>
      <SUserPic>
        {img ? (
          <SUserPicImg imgUrl={img} />
        ) : (
          name
            .split(' ')
            .slice(0, 2)
            .map((x) => x[0])
            .join('')
        )}
      </SUserPic>
        <div>
            <SName>{name}</SName>
            <SInfo>{i18next.t('formattedDateTime',
                {
                    val: date,
                    formatParams: {
                        val: {
                            weekday: 'long', 
                            year: 'numeric', 
                            month: 'long', 
                            day: 'numeric'
                        },
                    },
                })}
            </SInfo>
        </div>
    </SUserLabelContainer>
  )
}

export default UserLabel

const SUserLabelContainer = styled.div`
  display: flex;
  align-items: center;
`

const SUserPic = styled.div`
  width: 68px;
  height: 68px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #393939;
  color: #fff;
  border-radius: 50%;
  overflow: hidden;
  font-size: 20px;
  margin-right: 18px;
`

const SUserPicImg = styled.div`
  width: 100%;
  height: 100%;
  background-image: ${({ imgUrl }) => `url(${imgUrl})`};
  background-size: cover;
  background-repeat: no-repeat;
`

const SName = styled.div`
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 8px;
`

const SInfo = styled.div`
  font-size: 14px;
  line-height: 17px;
  opacity: 0.5;
`
