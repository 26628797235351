import React, { Suspense } from 'react'
import { Header, SideMenu, Preloader, Modal } from '@components'
import { withRouter } from 'react-router-dom'
import Layout from '@sc-reactkit/layout'
import { connect } from 'react-redux'

const Root = ({ children, modalData }) => {
  const SideMenuWithHistory = withRouter(SideMenu)

  return (
    <Layout>
      <Header />
      <SideMenuWithHistory>
        <Suspense fallback={<Preloader />}>{children}</Suspense>
      </SideMenuWithHistory>
      {modalData.visible && <Modal />}
    </Layout>
  )
}

const mapStateToProps = ({ app }) => ({ ...app })

export default connect(mapStateToProps)(Root)
