import React, { useEffect, useState } from 'react'
import { ThemeContext } from 'sc-context'
import { connect } from 'react-redux'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import {
  themeConstants,
  defaultTheme,
  darkTheme,
  appConstants,
} from '@constants'
import { SGlobalStyles } from '@style'
import { Root, AuthGuard } from '@components'
import { bindActionCreators } from 'redux'
import { appActions } from '@actions'
import { i18n } from '@helpers';
import { LocaleContext } from '@helpers';

const Main = React.lazy(() => import('@pages/Main'))
// const Journal = React.lazy(() => import('@pages/Journal'))
const Users = React.lazy(() => import('@pages/Users'))
const CallList = React.lazy(() => import(`@pages/CallList`))

const App = ({ auth, theme, startApp }) => {
  const themeKit = {
    theme: theme,
  }

  const [locale, setLocale] = useState(i18n.language);
  i18n.on('languageChanged', (lng) => setLocale(i18n.language));

  useEffect(() => {
    if (auth === appConstants.AUTH) {
      startApp()
    }
  }, [auth, startApp])

  return (
      <LocaleContext.Provider value={{locale, setLocale}}>
    <ThemeProvider
      theme={theme === themeConstants.DEFAULT ? defaultTheme : darkTheme}
    >
      <ThemeContext.Provider value={themeKit}>
        <SGlobalStyles />
        <Router>
          <AuthGuard>
            <Root>
              <Switch>
                <Route exact path="/headset" component={Main} />
                {/* <Route path="/journal" component={Journal} /> */}
                <Route path="/users" component={Users} />
                <Route path="/call-list" component={CallList} />
                <Redirect to="/headset" />
              </Switch>
            </Root>
          </AuthGuard>
        </Router>
      </ThemeContext.Provider>
    </ThemeProvider>
      </LocaleContext.Provider>
  )
}

const mapStateToProps = ({ app }) => ({
  ...app,
})

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(appActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
