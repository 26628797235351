import rootReducer from '@reducers'
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'

const store = (function () {
  let composed

  if (window.__REDUX_DEVTOOLS_EXTENSION__) {
    composed = compose(
      applyMiddleware(thunk),
      window.__REDUX_DEVTOOLS_EXTENSION__ &&
        window.__REDUX_DEVTOOLS_EXTENSION__(),
    )
  } else {
    composed = applyMiddleware(thunk)
  }
  return createStore(rootReducer, composed)
})()

export default store
