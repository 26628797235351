import { devicesConstants } from '@constants'
import api from '@api'
import { appActions } from '@actions'
import {hexToBase64} from "@helpers";

export const getDevices = ($offset, $limit) => {
  return async (dispatch) => {
    dispatch(appActions.toggleLoading())
    try {
      const { data } = await api.getDevices($offset, $limit)
      dispatch({
        type: devicesConstants.SET_DEVICES,
        payload: data,
      })
      dispatch(appActions.toggleLoading())
    } catch (error) {
      dispatch(appActions.toggleError(error))
      dispatch(appActions.toggleLoading())
    }
  }
}

export const setActiveDevice = (device) => {
  return (dispatch) => {
    dispatch({
      type: devicesConstants.SET_ACTIVE_DEVICE,
      payload: device,
    })
  }
}

export const bindUser = (userId) => {
  return async (dispatch, getState) => {
    const $state = getState()
    const $pubTr = $state.devices.activeDevice.pubTr
    try {
      await api.bindUser(userId, $pubTr)
      dispatch(getDevices(0, 100))
    } catch (error) {
      console.log(error)
    }
  }
}

export const unbindUser = () => {
  return async (dispatch, getState) => {
    const $state = getState()
    const $pubTr = $state.devices.activeDevice.pubTr
    try {
      await api.unbindUser($pubTr)
      dispatch(getDevices(0, 100))
    } catch (error) {
      console.log(error)
    }
  }
}

export const blockDevices = ($pubTrs) => {
  return async (dispatch) => {
    await api.blockDevices($pubTrs)
    dispatch(getDevices(0, 100))
  }
}

export const unblockDevices = ($pubTrs) => {
  return async (dispatch) => {
    await api.unblockDevices($pubTrs)
    dispatch(getDevices(0, 100))
  }
}

export const getBindingCandidate = (offset, limit) => {
  return async (dispatch) => {
    try {
      const { data } = await api.getBindingCandidate(offset, limit)
      dispatch({
        type: devicesConstants.GET_BINDING_CANDIDATE,
        payload: data,
      })
    } catch (error) {
      console.log(error)
    }
  }
}

export const addDevice = ($newDevice) => {
  return async (dispatch) => {
    const {
      pubTr,
    } = $newDevice
    try {
      await api.addDevice(
          hexToBase64(pubTr),
      )
      dispatch(getDevices(0, 100))
    } catch (err) {
      alert(`add device error`)
    }
  }
}