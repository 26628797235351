import { callConstants } from '@constants'

const initialState = {
  call: [],
}

export default function user(state = initialState, { type, payload = null }) {
  switch (type) {
    case callConstants.SET_CALL:
      return {
        ...state,
        call: payload,
      }
    default:
      return state
  }
}
