const data = {
  SET_DEVICES: 'SET DEVICES',
  SET_ACTIVE_DEVICE: 'SET ACTIVE DEVICE',
  BIND_USER: 'BIND USER',
  UNBIND_USER: 'UNBIND USER',
  BLOCK_DEVICE: 'BLOCK DEVICE',
  UNBLOCK_DEVICE: 'UNBLOCK DEVICE',
  GET_BINDING_CANDIDATE: 'GET BINDING CANDIDATE',
}

export default data
