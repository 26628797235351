import kalibri from 'kalibri'

import * as users from './_users'
import * as devices from './_devices'
import * as app from './_app'
import * as call from './_call'

export default (function () {
  let kalibriInstance = kalibri.createInstance({
    baseUrl: window._env_.API_URL,
    timeout: 10000,
    lsHeadersKeys: ['Authorization'],
  })

  return {
    ...users,
    ...devices,
    ...app,
    ...call,
    kalibri: kalibriInstance,
  }
})()
