import React from 'react'
import ModalKit, { ModalContent } from '@sc-reactkit/modal'
import Heading, { Text } from '@sc-reactkit/typography'
import Button, { DialogBar } from '@sc-reactkit/button'
import { connect } from 'react-redux'

const Modal = ({ modalData }) => {
  const { type, heading, text, successButton, cancelButton, success, cancel } =
    modalData

  const color = type === 'default' ? 'accent' : 'red'

  return (
    <ModalKit
      onClose={() => cancel()}
      style={{ maxWidth: '450px' }}
      isCloseByKey={true}
      onCloseKeyCode={27}
      isBackdropClose={true}
    >
      <ModalContent>
        <Heading>{heading}</Heading>
        <Text>{text}</Text>
      </ModalContent>
      <DialogBar>
        <Button kind="dialog" color={color} onClick={() => success()}>
          {successButton}
        </Button>
        <Button kind="dialog" onClick={() => cancel()}>
          {cancelButton}
        </Button>
      </DialogBar>
    </ModalKit>
  )
}

const mapStateToProps = ({ app }) => ({
  ...app,
})

export default connect(mapStateToProps)(Modal)
