import { colors } from '@constants'

export const formatedColor = {
  ACTIVE: colors.GREEN,
  block: colors.YELLOW,
  INACTIVE: colors.RED,
}

export const formatedStatus = {
  ACTIVE: 'userActiveStatus',
  INACTIVE: 'userInactiveStatus',
}
export const formatedGarniture = {
  ACTIVE: 'headsetActiveStatus',
  INACTIVE: 'headsetBlockedStatus',
}

export const base64ToHex = (str) => {
  const raw = atob(str);
  let result = '';
  for (let i = 0; i < raw.length; i++) {
    const hex = raw.charCodeAt(i).toString(16);
    result += (hex.length === 2 ? hex : '0' + hex);
  }
  return result.toUpperCase();
}

export const hexToBase64 = (str) => {
  return btoa(String.fromCharCode.apply(null,
      str.replace(/[\r\n]/g, "").replace(/([\da-fA-F]{2}) ?/g, "0x$1 ").replace(/ +$/, "").split(" "))
  );
}

export const formatedBlock = (blocked) => {
  return blocked ? 'callsBlockedStatus' : 'callsAllowedStatus'
}
