import React from 'react'
import PreloaderKit from '@sc-reactkit/preloader'
import styled from 'styled-components'

export const Preloader = () => {
  return (
    <SPreloaderContainer>
      <PreloaderKit />
    </SPreloaderContainer>
  )
}

const SPreloaderContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
