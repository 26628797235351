import { callConstants } from '@constants'
import api from '@api'
import { appActions } from '@actions'

export const getCall = ($offset, $limit) => {
  return async (dispatch) => {
    try {
      const { data } = await api.getCall($offset, $limit)
      dispatch({
        type: callConstants.SET_CALL,
        payload: data,
      })
    } catch (error) {
      dispatch(appActions.toggleError(error))
      dispatch(appActions.toggleLoading())
    }
  }
}
