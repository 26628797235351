import React from 'react'
import ErrorBlock from './_ErrorBlock'
import { useTranslation } from "react-i18next";

const DataError = () => {

    const { t } = useTranslation();

    return (
        <ErrorBlock text={t('connectionError')}/>
    )
}

export default DataError
