import { createGlobalStyle } from 'styled-components'

const SGlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html,body {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
  }

  #root {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: ${({ theme }) => theme.body};
  }

  a {
    text-decoration: none;
  }
`

export default SGlobalStyle
