import logoDefault from '@assets/logo-default.svg'
import logoDark from '@assets/logo-dark.svg'

export const colors = {
  GREEN: '#02AB3C',
  YELLOW: '#F0AB00',
  RED: '#DA1E28',
}

export const defaultTheme = {
  body: '#ffffff',
  backgroundColor: '#ffffff',
  color: '#000000',
  border: 'solid',
  backgroundImage: `url(${logoDefault})`,
}

export const darkTheme = {
  body: '#1a1a1a',
  backgroundColor: '#000000',
  color: '#292a36',
  border: 'none',
  backgroundImage: `url(${logoDark})`,
}
