import { combineReducers } from 'redux'

import app from './_app'
import users from './_users'
import devices from './_devices'
import call from './_call'

export default combineReducers({
  app,
  users,
  devices,
  call,
})
