import React from 'react'
import RootContainer, {
  PageContainer,
  MenuContainer,
} from '@sc-reactkit/menu-collapsable-container'
import Page from '@sc-reactkit/page'
import ContentBlock from '@sc-reactkit/content-block'
import Menu, { MenuItem } from '@sc-reactkit/menu'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { appActions } from '@actions'
import { useTranslation } from "react-i18next";

const SideMenu = ({ isMenuClose, history, toggleMenu, children }) => {
  const menuItemOnClick = async (link) => {
    await history.push(link)
    toggleMenu(true)
  }

  const { t } = useTranslation();

  return (
    <RootContainer>
      <MenuContainer isCollapsed={isMenuClose}>
        <Menu
          location={history.location.pathname}
          defaultOnClick={menuItemOnClick}
        >
          <MenuItem
            link="/"
            label={t('menuHeadsets')}
            icon="physical-devices_pc"
            isActive={history.location.pathname === '/'}
          />
          {/* <MenuItem
            link="/journal"
            label={t('menuEventLog')}
            icon="display_table-view"
            isActive={history.location.pathname === '/journal'}
          /> */}
          <MenuItem
            link="/users"
            label={t('menuUsers')}
            icon="users_user"
            isActive={history.location.pathname === '/users'}
          />
          <MenuItem
            link="/call-list"
            label={t('menuCalls')}
            icon="display_table-view"
            isActive={history.location.pathname === '/call-list'}
          />
        </Menu>
      </MenuContainer>
      <PageContainer>
        <Page>
          <ContentBlock>{children}</ContentBlock>
        </Page>
      </PageContainer>
    </RootContainer>
  )
}

const mapStateToProps = ({ app }) => ({
  ...app,
})

const mapDispatchtoProps = (dispatch) => ({
  ...bindActionCreators(appActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchtoProps)(SideMenu)
