const appConstants = {
  SWITCH_THEME: 'SWITCH THEME',
  TOGGLE_MENU: 'TOGGLE MENU',
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  AUTH: 'AUTH',
  NO_AUTH: 'NO AUTH',
  TOGGLE_LOADING: 'TOGGLE LOADING',
  TOGGLE_ERROR: 'TOGGLE ERROR',
  SET_MODAL: 'SET MODAL',
}

export default appConstants
