import { devicesConstants } from '@constants'

const initialState = {
  devices: [],
  activeDevice: {},
  bindingCandidate: [],
}

export default function data(state = initialState, { type, payload }) {
  switch (type) {
    case devicesConstants.SET_DEVICES:
      return {
        ...state,
        devices: payload,
      }
    case devicesConstants.SET_ACTIVE_DEVICE:
      return {
        ...state,
        activeDevice: payload,
      }
    case devicesConstants.SET_ACTIVE_NOTICE:
      return {
        ...state,
        activeNotice: payload,
      }
    case devicesConstants.BLOCK_DEVICE:
      return {
        ...state,
        devices: payload.newDevices,
        activeDevice: payload.device,
      }
    case devicesConstants.UNBLOCK_DEVICE:
      return {
        ...state,
        devices: payload.newDevices,
        activeDevice: payload.device,
      }
    case devicesConstants.GET_BINDING_CANDIDATE:
      return {
        ...state,
        bindingCandidate: payload,
      }
    default:
      return state
  }
}
