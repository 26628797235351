import { ls } from '@helpers'
import { appConstants } from '@constants'

const initialState = {
  theme: JSON.parse(localStorage.getItem('theme')) || 'dark',
  isMenuClose: true,
  auth: sessionStorage.getItem('auth'),
  loading: false,
  error: false,
  modalData: { visible: false },
}

export default function app(state = initialState, { type, payload }) {
  switch (type) {
    case appConstants.SWITCH_THEME:
      ls.set('theme', payload)
      return {
        ...state,
        theme: payload,
      }
    case appConstants.TOGGLE_MENU:
      return {
        ...state,
        isMenuClose: payload,
      }
    case appConstants.LOGIN:
      sessionStorage.setItem('auth', appConstants.AUTH)
      return {
        ...state,
        auth: payload,
      }
    case appConstants.LOGOUT:
      sessionStorage.clear()
      return {
        ...state,
        auth: payload,
      }
    case appConstants.TOGGLE_LOADING:
      return {
        ...state,
        loading: payload,
      }
    case appConstants.TOGGLE_ERROR:
      return {
        ...state,
        error: payload,
      }
    case appConstants.SET_MODAL:
      return {
        ...state,
        modalData: payload,
      }
    default:
      return state
  }
}
